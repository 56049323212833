import React, { createRef } from 'react';
import { graphql } from 'gatsby';

import moment from 'moment';
// import 'moment/locale/de';

import BackButton from '../../components/BackButton/BackButton';
import Section from '../../components/Section/Section';
import PreFooter from '../../components/PreFooter/PreFooter';
import ContentLayout from '../../components/ContentLayout/ContentLayout';
import SEO from '../../components/SEO/SEO';
import { sanitizer } from '../../util';
import TermWrapper from '../../components/TermWrapper/TermWrapper';

export default ({ data, pageContext }) => {
  const { page, subNav, activeSubNavLabel } = data;
  const { title, updatePostFields, dateGmt, updateTags, seo } = page;
  const { contactUs, author, body } = updatePostFields;

  const ref = createRef();

  // TODO: localize date somehow globally with moment.js
  // moment.locale('de');

  return (
    <>
      <SEO seoData={seo} uri={page.uri} dateGmt={page.dateGmt} modifiedGmt={page.modifiedGmt} />
      <ContentLayout
        id="PressReleasePost"
        subNav={pageContext.subNavLocation ? subNav : null}
        activeSubNavLabel={activeSubNavLabel}
        pageContext={pageContext}
      >
        <Section containerRef={ref} id="blogItem" class="container">
          <div className="wrapper presswrapper">
            <div className="viewallwrapper">
              <div className="viewspace" />
              {/* TODO: fix back button */}
              <BackButton ref={ref} url="/updates/" title="Back to Updates" />
            </div>
            <h1>{title}</h1>
            <div className="date">{moment(dateGmt).format('LL')}</div>
            <TermWrapper terms={updateTags} />
            <span>{author}</span>
            <div className="postbody" dangerouslySetInnerHTML={{ __html: sanitizer(body, true, {}) }} />
          </div>
        </Section>
        {contactUs && (
          <PreFooter
            data={{
              ...contactUs,
              id: 'contact_us',
              name: 'Press_Release_Contact_Bottom',
              theme: 'green',
              horizontallyReversed: true,
              croppingImage: true,
              withCTA: false,
              overflow: false,
            }}
          />
        )}
      </ContentLayout>
    </>
  );
};

export const query = graphql`
  query update(
    $databaseId: Int!
    $nextPage: Int
    $previousPage: Int
    $subNavLocation: WpMenuLocationEnum
    $activeSubPath: String
  ) {
    page: wpUpdate(databaseId: { eq: $databaseId }) {
      ...UpdatePostContent
    }
    subNav: wpMenu(locations: { eq: $subNavLocation }) {
      ...SubNavigation
    }
    activeSubNavLabel: wpMenuItem(locations: { eq: $subNavLocation }, url: { eq: $activeSubPath }) {
      label
    }
    nextPage: wpUpdate(databaseId: { eq: $nextPage }) {
      title
      uri
    }
    previousPage: wpUpdate(databaseId: { eq: $previousPage }) {
      title
      uri
    }
  }
`;

export const fragments = graphql`
  fragment UpdatePostContent on WpUpdate {
    title
    uri
    uri
    dateGmt
    modifiedGmt
    seo {
      ...SeoData
    }
    updateTags {
      nodes {
        name
        uri
      }
    }
    updatePostFields {
      author
      body
      contactUs {
        description
        image {
          ...FluidImage
        }
        title
      }
    }
  }
`;
